/* eslint-disable import/no-anonymous-default-export */
export default {
  test: {
    title: "Anikana English",
  },

  arcana: "ARCANA",
  persona: "PERSONA",

  warning_messages: {
    changeLanguage: "Are you sure to change Language?",
  },

  success_messages: {
    textCopy: "Copied",
  },

  emptylist_messages: {
    noTradingHistory: "No Trading History",
    noOrderHistory: "No Order History",
  },

  ascendingOrder: "Old Order",
  descendingOrder: "New Order",
  textCount: "items",
  textSearchBy: "Search by name or creator",

  textCancel: "Cancel",
  textDelete: "Delete",
  textBack: "Back",
  textLogout: "Logout",

  navBar: {
    home: "Home",
    features: "Feautres",
    nfts: "NFTs",
    guide: "Guide",
    news: "News",
    contact: "Contact",
    privacy: "Privacy",
    terms: "Terms",
    commerceLaw: "利用規約",
  },

  footer: {
    home: "Home",
    features: "Feautres",
    nfts: "NFTs",
    guide: "Guide",
    news: "News",
    contact: "Contact",
    privacy: "Privacy",
    terms: "Terms",
    commerceLaw: "利用規約",
    policy: "Privacy Policy",
    tearm_of_use: "Terms of Use",
  },

  home: {
    newArrivals: {
      title: "NEW ARRIVALS",
      viewMore: "view more",
    },

    features: {
      title: "FEATURES",
    },

    guide: {
      title: "GUIDE",
    },

    faq: {
      title: "FAQ",
    },

    contact: {
      title: "CONTACT",
      labelName: "Name",
      labelEmail: "Email",
      labelMessage: "Message",
      placeHolderName: "Enter your name",
      placeHolderEmail: "Enter your email",
      placeHolderMessage: "Enter your question",
      buttonSend: "SEND",
    },
  },

  news: {
    title: "News",
    buttonAll: "All",
  },

  nftSearch: {
    list: "List",
    sideBarWrapper: {
      title: "Search",
      category: "Category",
      basicInformation: "Basic Information",
      score: "Score",
      availability: "Availability",
      onSale: "On Sale",
    },
  },

  myPageWrapper: {
    title: "MY PAGE",
    accountDetailButton: "Account Details & Editing",
    walletLabel: "Anikana Wallet",
    subtitle: {
      myTokens: "My Tokens",
      transaction: "Transaction",
      offer: "Offer",
      save: "Save",
      establishment: "Establishment",
    },
    list: {
      holdingArcana: "Holding Arcana",
      holdingPersona: "Holding Persona",
      buyTransactions: "Buy Transactions",
      sellTransactions: "Sell Transactions",
      incomingOffers: "Incoming Offers",
      outgoingOffers: "Outgoing Offers",
      bookmark: "Bookmark",
      browsingHistory: "Browsing History",
      account: "Account",
      receivingAccountInformation: "Receiving Account Information",
      functionSettings: "Function Settings",
    },
  },

  setting: {
    title: "Function settings",
    subtitle: {
      notificationFunction: "Notification function",
      language: "Change Language",
    },
    label: {
      executionNotice: "Notification function",
      transactionFailureNotification: "Transaction failure notification",
      language: "Language",
    },
  },

  account: {
    label: {
      walletAddress: "Wallet Address",
      userNo: "User No",
      emailAddress: "E-mail Address",
      phoneNumber: "Mobile Phone Number",
      password: "Password",
    },
    buttonAccountDelete: "Delete",
    infoEmail: {
      title: "Update Email Address",
      labelEmail: "Update email address",
      placeHolderEmail: "Please enter your email address",
      buttonSendEmail: "Send an email",
      labelCode: "Code",
      placeHolderConfirmCode: "Enter your code",
      buttonConfirm: "Confirm",
    },
    infoPhone: {
      title: "Phone Number Update",
      labelPhone: "telephone number",
      placeHolderPhone: "Please enter your phone number",
      buttonSendSMS: "Send SMS",
      labelCode: "code",
      placeHolderConfirmCode: "Enter your code",
      buttonConfirm: "Confirm",
    },
    infoPassword: {
      title: "Change Password",
      labelCurrentPassword: "current Password",
      labelNewPassword: "new",
      labelConfirmPassword: "confirmation",
      placeHolderCurrentPassword: "Please enter your current password",
      placeHolderNewPassword: "Enter your new password",
      placeHolderConfirmPassword: "Enter confirmation password",
      buttonResetPassword: "Reset your password",
    },
    infoAccountName: {
      title: "Update Account Name",
      labelAccountName: "account name",
      placeHolderAccountName: "Please enter your account name",
      buttonConfirm: "Confirm",
    },
    infoDelete: {
      title: "Delete",
      labelDescription:
        "If you don't mind, please let me know the reason for leaving",
      titleNote: "Notes",
      textNote1:
        "If the user wishes to withdraw from the service, the user may withdraw from the service by following the procedures prescribed by the company.",
      textNote2:
        "If the user withdraws from this service, the Company will not be obligated to store data such as user information.",
      textNote3:
        "The usage contract shall be terminated at the time when the user withdraws.",
      labelAgree: "I agree to the notes",
      buttonDelete: "Permanently delete your account",
      textModal: "We will process your delete. Is it OK?",
    },
  },

  payment: {
    title: "Receiving account information",
    label: {
      bankName: "Bank Name",
      bankCode: "Bank Code",
      branchName: "Branch Name",
      branchCode: "Branch Code",
      accountType: "Account Type",
      accountNumber: "Account Number",
      accountHolderName: "Account Holder Name",
    },
    buttonEdit: "Edit",
    buttonCancel: "Cancel",
    buttonSave: "Save",
  },
};
