import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, TextBox, AutoCompleteSelect } from '../../components/Form'
import MyPageWrapper from '../../components/MyPageWrapper'
import { getData } from '../../services/fetch';
import PaymentSelect from 'react-select';
import { BankAccountType } from '../../components/enum'
import { translations } from "../../services/localization";

const Payment = () => {
    const navigate = useNavigate();
    const [financial_institution_name, setFinancial_institution_name] = useState('');
    const [financial_institution_code, setFinancial_institution_code] = useState('');
    const [branch_name, setBranch_name] = useState('');
    const [branch_code, setBranch_code] = useState('');
    const [account_type, setAccount_type] = useState('');
    const [account_number, setAccount_number] = useState('');
    const [account_holder_name, setAccount_holder_name] = useState('');
    const ref_branch_name = React.createRef(null);

    const [show_financial_institution_name, setShow_financial_institution_name] = useState(true);
    const [show_bank_code, setShow_bank_code] = useState(false);

    const content = translations.payment;
    const contentLabel = translations.payment.label;

    useEffect(() => {
        getAccountInfo();

        return () => {
            clear();
        }
    }, []);

    const getAccountInfo = async () => {
        var response = await getData(`api/bank-accounts/get-bank-detail`);
        if (response.ok) {
            const { bankName, bankCode, branchName, branchCode, accountType, accountNumber, accountHolderName } = response.data;
            setFinancial_institution_name(bankName);
            setFinancial_institution_code(bankCode)
            setBranch_name(branchName);
            setBranch_code(branchCode);
            setAccount_type(Object.keys(BankAccountType).find(key => BankAccountType[key] === accountType));
            setAccount_number(accountNumber);
            setAccount_holder_name(accountHolderName);
        }
    }

    const clear = () => {
        setFinancial_institution_name('');
        setBranch_name('');
        setAccount_type('');
        setAccount_number('');
        setAccount_holder_name('');
    }

    return (
        <MyPageWrapper disabledSubNav pageid='お支払い'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8 col-12'>
                        <div className='d-flex flex-row align-items-end mb-4'>
                            <h3 className='text-start mb-3'>{content.title}</h3>
                        </div>
                        {/* <PaymentDesc label={'支店名'} /> */}
                        <PaymentDesc label={contentLabel.bankName} value={financial_institution_name} disabled={true} />
                        <PaymentDesc label={contentLabel.bankCode} value={financial_institution_code} disabled={true} />
                        <PaymentDesc label={contentLabel.branchName} value={branch_name} disabled={true} />
                        <PaymentDesc label={contentLabel.branchCode} value={branch_code} disabled={true} />
                        <PaymentDesc label={contentLabel.accountType} value={account_type} disabled={true} />
                        <PaymentDesc type="number" label={contentLabel.accountNumber} value={account_number} disabled={true} />
                        <PaymentDesc label={contentLabel.accountHolderName} value={account_holder_name} disabled={true} />
                        <div className='d-flex justify-content-center justify-content-sm-end my-5'>
                            <Button variant={2} onClick={() => navigate("/mypage/payment/edit")} className="btn-edit btn-mini">
                                <span>{content.buttonEdit}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </MyPageWrapper>
    )
}

export default Payment

const PaymentDesc = props => {
    const { label, value, disabled } = props;
    return (
        <div className='row mb-4'>
            <div className="col-sm-3 d-flex align-items-center">
                <span>{label}</span>
            </div>
            <div className='col-sm'>
                <TextBox variant={3} value={value} disabled={disabled} />
            </div>
        </div>
    )
}
