import React from "react";
import SweetAlert from "sweetalert2";
import { ReactComponent as Close } from "../assets/icons/close.svg";

const swalOption = {
  customClass: {
    popup: "modal-box-bordered",
    confirmButton: "btn btn-custom-4 text-white btn-mini d-block mb-4",
    cancelButton: "btn btn-custom-4 text-white btn-mini mb-4",
    title: "modal-title",
    htmlContainer: "modal-text",
  },

  // buttonsStyling: false
};

const defaultParams = {
  allowOutsideClick: false,
  allowEscapeKey: false,
  showCloseButton: true,
  closeButtonHtml: '<div class="close-icon"></div>',
  focusConfirm: false,
};

const Swal = {
  fire: (params) =>
    SweetAlert.mixin(swalOption).fire(
      params.icon === "error"
        ? {
            ...params,
            ...defaultParams,
            iconHtml: '<div class="error-icon"></div>',
          }
        : {
            ...params,
            ...defaultParams,
            iconHtml: params.hideIcon ? "" : '<div class="success-icon"></div>',
          }
    ),
};

export default Swal;
