import { createStore } from "react-hooks-global-state";
import { TokenType, WrapperType } from "./enum";

const initialState = {
  filter: { category: TokenType.Arcana, search: "" },
  searchCount: 0,
  sidebarType: WrapperType.Search,
  notificationCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "updateFilter":
      state.filter[action.key] = action.value;
      return { ...state, filter: JSON.parse(JSON.stringify(state.filter)) };
    case "update":
      return { ...state, [action.key]: action.value };
    case "resetFilter":
      return {
        ...state,
        filter: JSON.parse(
          JSON.stringify({ category: action.value, search: "" })
        ),
      };
    case "reset":
      return { ...state, ...initialState };
    case "increaseSearchCount":
      return { ...state, searchCount: state.searchCount + 1 };
    default:
      return state;
  }
};
export const { dispatch, useStoreState } = createStore(
  reducer,
  JSON.parse(JSON.stringify(initialState))
);
