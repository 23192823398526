import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./index.css";
import bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/scss/app.scss";
import "./assets/scss/input_type.scss";
import "./assets/scss/mypage.scss";
import "./assets/scss/sidebar.scss";
import "./assets/scss/sidebarBeside.scss";
import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import "react-multi-carousel/lib/styles.css";
import ReactModal from "react-modal";

const root = ReactDOM.createRoot(document.getElementById("root"));

ReactModal.setAppElement("#root");
root.render(
  // <React.StrictMode>
  // <BrowserRouter>
  <App />
  // </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
