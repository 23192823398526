import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Loading from '../components/Loading'

const Page404 = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/");
    }, [])
    return (
        // <Loading />
        <></>
    )
}

export default Page404