/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Button } from '../../components/Form'
import Modal from '../../components/Modal'
import MyPageWrapper from '../../components/MyPageWrapper'
import SubNav from '../../components/SubNav'
import QRCode from 'react-qr-code'
import { getData } from '../../services/fetch'
import { formatMoney } from '../../components/commonFnc';
import moment from 'moment'
import { CloseIcon } from '../../components/Icon'
import { OrderType, TradeStatus } from '../../components/enum'
import { AuthContext } from '../../context/authContext'
import Loading from '../../components/Loading'
import { BrowserView, MobileView } from 'react-device-detect'
import { ReactComponent as LevicaLogo } from '../../assets/images/levica-logo.svg';

const TradeDetail = () => {
    const location = useLocation();
    const default_type = location.state?.type ?? '';
    const { id } = useParams();
    const { user } = useContext(AuthContext);
    const [type, setType] = useState(default_type);
    const [isOpen, setIsOpen] = useState(false);
    const [trade, setTrade] = useState(undefined);
    const [comission, setCommition] = useState(null);
    const navigate = useNavigate();

    const getTrade = async (id) => {
        var response = await getData(`api/trades/trade-detail?trade_id=${id}`);
        if (response.ok) {
            setTrade(response.data.trade);
            setCommition(response.data.trade?.commission);
            setType(response.data.trade?.ask_order.user_id == user.id ? OrderType.Sell : OrderType.Buy)
        }
    }

    useEffect(() => {
        if (id) {
            getTrade(id);
        }

        return () => {
            setTrade(null);
        }
    }, [id, user])

    return (
        <MyPageWrapper pageid={type === OrderType.Buy ? "買い取引" : "売り取引"}>
            <SubNav pageid={type === OrderType.Buy ? "買い取引" : "売り取引"} items={[
                {
                    id: '買い取引',
                    name: '買い取引',
                    to: '/mypage/buy-transactions',
                },
                {
                    id: '売り取引',
                    name: '売り取引',
                    to: '/mypage/sell-transactions',
                },
            ]} />
            {/* <div className='row mb-3'>
                <div className='col-12'>
                    <a onClick={() => navigate(-1)}>
                        <i className="bi bi-chevron-left h3"></i>
                    </a>
                </div>
            </div> */}
            {
                !trade ? <Loading /> :
                    <>
                        <div className='row'>
                            <div className='col-12'>
                                <h3 className='title text-start'>取引詳細</h3>
                                <span><small>取引ID:{trade?.id}</small></span>
                                <p><small>ブロックチェーントランザクション：{type === OrderType.Buy ? (trade?.token_receive_transaction_id || '') : (trade?.token_send_transaction_id || '')}</small></p>

                                {trade?.status === TradeStatus.ERROR ?
                                    <div className="alert alert-default" role="alert">
                                        <h4 className='text-danger fw-400'>エラー</h4>
                                        <span>取引中にエラーが発生したため、キャンセルされました。</span>
                                    </div>
                                    : <></>
                                }
                                {trade?.status === TradeStatus.TOKEN_RECEIVED ?
                                    <div className="alert alert-default" role="alert">
                                        <h4 className='text-danger fw-400'>支払い待ち</h4>
                                        <span>{type === OrderType.Sell ? '買い手の決済待ちです。' : ''}</span>
                                    </div>
                                    : <></>
                                }
                                {trade?.status === TradeStatus.TRADED ?
                                    <div className="alert alert-default" role="alert">
                                        <h4 className='text-primary fw-400'>取引完了</h4>
                                        <span>取引が正常に完了しました。</span>
                                    </div>
                                    : <></>
                                }
                                {trade?.status !== TradeStatus.TOKEN_RECEIVED && trade?.status !== TradeStatus.ERROR && trade?.status !== TradeStatus.TRADED ?
                                    <div className="alert alert-default" role="alert">
                                        <h4 className='text-danger fw-400'>取引中</h4>
                                        <span>取引実行中です。しばらくお待ち下さい。</span>
                                    </div>
                                    : <></>
                                }


                                <div className='row px-lg-5 pt-3'>
                                    <div className='col-6 col-lg-9 text-end'>
                                        {type === OrderType.Sell ? "販売金額" : "購入金額"}

                                    </div>
                                    <div className='col-6 col-lg-3 text-end'>
                                        {formatMoney(trade?.amount, trade?.currency)}
                                    </div>
                                </div>
                                {type === OrderType.Sell ?
                                    <div className='row px-lg-5 pt-3'>
                                        <div className='col-6 col-lg-9 text-end'>
                                            販売手数料
                                        </div>
                                        <div className='col-6 col-lg-3 text-end'>
                                            {formatMoney(comission, trade?.currency)}
                                        </div>
                                    </div>
                                    : <>{type}</>
                                }
                                <div className="divider my-3"></div>
                                <div className='row px-lg-5 pt-3'>
                                    <div className='col-6 col-lg-9 text-end'>
                                        入金金額
                                    </div>
                                    <div className='col-6 col-lg-3 text-end'>
                                        <h4>{formatMoney(type === OrderType.Sell ? trade?.transfer_amount : trade?.amount, trade?.currency)}</h4>
                                    </div>
                                </div>
                                {/* <div className='flex-center'>
                                   <Button variant={2} onClick={() => { setIsOpen(true) }} className="w-100 my-4" style={{ maxWidth: 400 }}>内容を確認して決済する</Button>
                                </div>
                                {
                                   type === OrderType.Buy && trade?.status === TradeStatus.TOKEN_RECEIVED ?
                                       <div className='flex-center'>
                                           <Button variant={2} onClick={() => { setIsOpen(true) }} className="w-100 my-4" style={{ maxWidth: 400 }}>内容を確認して決済する</Button>
                                       </div> : <></>
                                } */}
                            </div>
                        </div>
                        <div className='row mt-3 mb-5'>
                            <div className='col-12'>
                                <h3 className='text-start mb-4'>取引情報</h3>
                                <div className='row'>
                                    <div className='col-12 col-lg-4'>
                                        <img src={trade?.token ? trade?.token['image'] : ''} className='w-100' />
                                    </div>
                                    <div className='col-12 col-lg-8 pt-3'>
                                        <p>名前：{trade?.token ? trade?.token['name'] : ''}</p>
                                        <p>落札日：{moment(trade?.matched_at).format("yyyy/MM/D")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal isOpen={isOpen} onRequestClose={() => { setIsOpen(false) }}>
                            <div className='flex-center flex-column pt-3 pb-5'>
                                <CloseIcon className="ms-auto pointer mb-3" onClick={() => setIsOpen(false)} />
                                {/* <h5 className='mb-5 text-center'>LEVICAアプリから決済をしてください</h5> */}
                                <div className='flex-center flex-column'>
                                    <MobileView>
                                        {
                                            trade?.bid_order && trade?.bid_order.qr_code &&
                                            <img src={`data:image/jpeg;base64,${trade?.bid_order.qr_code}`} className="float-center" />
                                        }
                                        {/* <QRCode value={type === OrderType.Sell ? (trade?.token_received_transaction_id || 'sell') : (trade?.token_send_transaction_id || 'buy')} className='float-center' /> */}
                                        <div className='d-flex justify-content-around mt-3'>
                                            <p className='h3 fw-400 text-center'>合計</p>
                                            <p className='h3 fw-400 text-center' style={{paddingLeft: 5}}>{formatMoney(trade?.amount, trade?.currency)}</p>
                                        </div>
                                    </MobileView>
                                    <BrowserView>
                                        <LevicaLogo style={{ width: 70, marginBottom: 8 }} />
                                        <Button variant={2} onClick={() => { }} className="w-100 float-center">{'LEVICA決消る'}</Button>
                                    </BrowserView>
                                </div>
                            </div>
                        </Modal>
                    </>
            }
        </MyPageWrapper>
    )
}

export default TradeDetail
