import React from 'react'

const BorderGradient = (props) => {
    const { children } = props;
    return (
        <div className='gradient'>
            {children}
        </div>
    )
}



export default BorderGradient