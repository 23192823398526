/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Item, { CategoryCarousel, ItemCarousel } from "../components/Item";
import { getData, postData } from "../services/fetch";
import { Button, SearchBox } from "../components/Form";
import Swal from "../components/Swal";
import {
  CollectionIcon,
  NFTListIcon,
  OfferHammerIcon,
  PlusCircleIcon,
  PlusGradientCircleIcon,
  WalletIcon,
} from "../components/Icon";
import Guide from "./guide";
import FAQs from "./FAQs";
import Footer from "../components/Footer";
import Contact from "./Contact";
import { TokenType } from "../components/enum";
import { dispatch } from "../components/global";
import {
  addGradientColorByClass,
  removeAllGradientColor,
  scrollWidthOffset,
} from "../components/commonFnc";
import { ReactComponent as ArcanaSVG } from "../assets/icons/arcana_icon.svg";
import { ReactComponent as ArcanaWhiteSVG } from "../assets/icons/arcana_white.svg";
import { ReactComponent as PersonaSVG } from "../assets/icons/persona_icon.svg";
import { ReactComponent as PersonaGraySVG } from "../assets/icons/persona_gray.svg";
import { MobileFirstView } from "../components/HomeComponent";
import { dummyTokens } from "../data/dummyData";
import { translations } from "../services/localization";
import Loading from "../components/Loading";
import ScrollContainer from "react-indiana-drag-scroll";

const Home = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [type, setType] = useState(TokenType.Arcana);
  const [search, setSearch] = useState("");
  const { hash } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const content = translations.home;
  const checkAllLogout = async () => {
    if (searchParams.get("logout") == "true") {
      var response = await postData(`api/logout-all-users`, {});
      if (response.ok) {
        console.log("SUCCEED LOGOUT");
        //alert(response.data.message);
        //
      }
    }
  };

  const goToNFTs = (type) => {
    dispatch({ type: "updateFilter", key: "category", value: type });
    navigate("/search");
  };

  const getItems = async (type) => {
    // // Dummy data
    // var token_list = dummyTokens.tokens;
    // token_list.map(x => x['new'] = 1);
    // setItems(token_list);
    setLoading(true);
    var response = await postData(`api/tokens/search`, {
      token_name: "",
      sort_order: "DESC",
      token_type_id: type,
      filter_whitelist: true,
    });
    if (response.ok) {
      if (response.data.results) {
        var token_list = response.data.results.tokens;
        token_list.map((x) => (x["token_type_id"] = type));
        token_list.map((x) => (x["new"] = 1));
        setItems(token_list);
      }
    }
    setTimeout(() => setLoading(false), 500);
  };

  const changeLanguage = async (lang) => {
    var response = await getData(`api/jp`, true);
  };

  const onSearch = (e) => {
    e.preventDefault();
    dispatch({ type: "updateFilter", key: "search", value: search });
    navigate(`/search`);
  };

  useEffect(() => {
    let navClass;
    switch (hash) {
      case "#features":
        navClass = "navFeature";
        break;
      case "#nfts":
        navClass = "navNFTs";
        break;
      case "#guide":
        navClass = "navGuide";
        break;
      case "#contact":
        navClass = "navContact";
        break;
      default:
        navClass = "navHome";
        break;
    }
    if (navClass) {
      addGradientColorByClass(navClass);
      scrollWidthOffset(document.getElementsByClassName(navClass)[0]);
    }
    changeLanguage();

    return () => {
      removeAllGradientColor();
    };
  }, []);

  useEffect(() => {
    //check logout all sessions
    checkAllLogout();
    getItems(type);

    return () => setItems([]);
  }, [type]);

  if (loading) return <Loading className="h-100" />;

  return (
    <div id="home">
      <section className="container-fluid px-lg-5 mb-3">
        {/* First View */}
        <section>
          {/* Mobile First View */}
          <div className="d-sm-none">
            <MobileFirstView />
          </div>
          {/* PC First View */}
          <div className="row mb-5 d-none d-sm-flex">
            <div className="col-md-6 col-12 bg-global my-5">
              <div className="content d-flex flex-column justify-content-center h-100">
                <div className="mb-5">
                  <h1 className="title">
                    An <span className="title text-rainbow">NFT</span>{" "}
                    Marketplace,
                  </h1>
                  <h1 className="title m-lg-5">
                    a new{" "}
                    <span className="title text-rainbow">Experience!</span>{" "}
                  </h1>
                </div>
                <SearchBox
                  placeholder={translations.textSearchBy}
                  onSubmit={onSearch}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
            </div>
            <div className="col-md-6 col-12 my-5">
              <div className="row gx-xl-5 gx-lg-3 gx-2">
                <div className="col-6">
                  <CategoryCarousel
                    icon={<ArcanaSVG />}
                    title="Arcanas"
                    renderIndicator={false}
                    onClick={() => goToNFTs(TokenType.Arcana)}
                    images={[require("../assets/images/arcana_img.jpg")]}
                  />
                </div>
                <div className="col-6">
                  <CategoryCarousel
                    icon={<PersonaSVG />}
                    // comingsoon
                    title="Personas"
                    onClick={() => goToNFTs(TokenType.Persona)}
                    images={[require("../assets/images/persona_img.jpg")]}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* New Arrivals */}
        <section>
          <div className="row" id="nfts">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <h2 className="text-start title me-lg-5 me-3">
                  {content.newArrivals.title}
                </h2>
                <div className="d-none d-sm-block">
                  <Button
                    variant={2}
                    onClick={() => {
                      setType(TokenType.Persona);
                    }}
                    className={`float-end btn-reset text-uppercase btn-bold h-100 ${
                      type === TokenType.Persona ? "" : "btn-disable"
                    } me-3`}
                  >
                    {type === TokenType.Persona ? (
                      <PersonaSVG
                        style={{ width: 30, height: 30, marginRight: 3 }}
                      />
                    ) : (
                      <PersonaGraySVG
                        style={{ width: 30, height: 30, marginRight: 3 }}
                      />
                    )}
                    {translations.persona}
                  </Button>
                  <Button
                    variant={2}
                    onClick={() => {
                      setType(TokenType.Arcana);
                    }}
                    className={`float-end btn-reset text-uppercase btn-bold h-100 ${
                      type === TokenType.Arcana ? "" : "btn-disable"
                    } me-3`}
                  >
                    <ArcanaWhiteSVG
                      style={{ width: 30, height: 30, marginRight: 3 }}
                    />
                    {translations.arcana}
                  </Button>
                </div>
              </div>
              <div>
                <a
                  className="nav-link d-flex align-items-center mb-0 ms-5 pull-right view-more"
                  href={"#"}
                  onClick={() => {
                    goToNFTs(type);
                  }}
                >
                  <p
                    className="title mb-0 me-2 gradient-text"
                    style={{ marginTop: -2 }}
                  >
                    {content.newArrivals.viewMore}
                  </p>
                  <PlusGradientCircleIcon className={"gradient-icon"} />
                </a>
              </div>
            </div>

            <div className="d-sm-none my-2">
              <Button
                variant={2}
                onClick={() => {
                  setType(TokenType.Arcana);
                }}
                className={`btn-reset text-uppercase btn-bold h-100 ${
                  type === TokenType.Arcana ? "" : "btn-disable"
                } me-3`}
              >
                {translations.arcana}
              </Button>

              <Button
                variant={2}
                onClick={() => {
                  setType(TokenType.Persona);
                }}
                className={`btn-reset text-uppercase btn-bold h-100 ${
                  type === TokenType.Persona ? "" : "btn-disable"
                } me-3`}
              >
                {translations.persona}
              </Button>
            </div>
            <div className="col-12 mt-4">
              <div className="" style={{ minHeight: 394, marginBottom: 196 }}>
                {/* {items.length > 0 ? <ItemCarousel items={items} /> : <></>} */}
                {items && (
                  <ScrollContainer className="d-flex gap-2 horiz-scroll-container child-smoothable-transition">
                    {items.map((item, index) => (
                      <Item
                        key={index}
                        item={item}
                        onClickBtn={() =>
                          navigate(
                            `/detail/${item.token_type_id}/${item.token_id}`
                          )
                        }
                      />
                    ))}
                  </ScrollContainer>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* Features */}
      <section>
        <div className="row feature py-5 mx-0 gx-5" id="features">
          <div className="col-12">
            <div className="container px-lg-5">
              <h2 className="title text-center mb-3">
                {content.features.title}
              </h2>
              <div className="row pt-5 gx-5 fix-max-width">
                <div className="col-md-3">
                  <Feature
                    icon={<WalletIcon />}
                    title="ウォレットを作る"
                    text="Octillionでweb3authのサービスを使って、ANICANA Walletを作成します。Walletを活用することで、自分のトークンなど一括管理できます。"
                  />
                </div>
                <div className="col-md-3">
                  <Feature
                    icon={<CollectionIcon />}
                    title="自分のコレクション"
                    text="様々なNFTを集めて、自分のコレクションを作りましょう！NFTの中には独特なものであるPERSONAやARCANAも含まれています。"
                  />
                </div>
                <div className="col-md-3">
                  <Feature
                    icon={<NFTListIcon />}
                    title="NFTを出品する"
                    text="自分のコレクションの中から好きなNFTを出品し、自由に売りに出すことが可能となっています。"
                  />
                </div>
                <div className="col-md-3">
                  <Feature
                    icon={<OfferHammerIcon />}
                    title="買いや売りのオファー"
                    text="自分のNFTを売りに出すことだけではなく、他にユーザーが所有しているNFTに対して買いオファーを出すこともできます。"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Guide */}
      <section>
        <div
          className="container-fluid px-lg-5 mt-5 pt-5 guide fix-max-width"
          id="guide"
        >
          <div className="row px-lg-5">
            <div className="col">
              <h2 className="text-center title mb-3">{content.guide.title}</h2>
              <div className="row pt-5 gx-3 gx-xl-5">
                <div className="col-md-4 col-sm-12">
                  <GuideInfo
                    id={1}
                    title="OCTILLIONアカウント登録やANICANA Walletの設定方法"
                    image={require("../assets/images/guide/how_to_register/how_to_register_guide.webp")}
                  />
                </div>
                <div className="col-md-4">
                  <GuideInfo title="買い希望（BID）や買取引について" />
                </div>
                <div className="col-md-4">
                  <GuideInfo title="売り希望（ASK）や売取引について" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FAQs */}
      <section>
        <FAQs />
      </section>
      {/* Contact */}
      <section>
        <Contact />
      </section>
      {/* Footer */}
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Home;

const Feature = (props) => {
  const { icon, title, text } = props;
  return (
    <div className="row px-3 mb-5">
      <div className="col-12 text-center flex-center" style={{ height: 50 }}>
        {icon}
      </div>
      <div className="col-12">
        <p className="title text-center my-3">{title}</p>
      </div>
      <div className="col-12">
        <p className="text-justify">{text}</p>
      </div>
    </div>
  );
};

const GuideInfo = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className={`card item-card guide-card mb-3 ${
        props.id ? "active pointer" : ""
      }`}
      onClick={() => {
        if (props.id) {
          navigate(`/guide/${props.id}`);
        }
      }}
    >
      <div className="card-image">
        {props.image ? (
          <img className="card-image pointer" src={props.image} />
        ) : (
          <div className="coming-soon">
            <span className="fw-400">Coming Soon</span>
          </div>
        )}
      </div>
      <div className="card-body" style={{ height: 110 }}>
        <p className="title text-center fw-100">{props.title}</p>
      </div>
    </div>
  );
};
