import React from 'react'
import { Link } from 'react-router-dom';

const SubNav = (props) => {
    const { items } = props;
    return (
        <nav className={`navbar-expand-lg ${props.className || ''}`} id='subNav'>
            <div className="">
                
                <div className='nav-pills nav-align'>
                {
                        items && items.length > 0 && items.map((item, index) =>
                            <div key={index} style={{width: '200px', textAlign:'center'}} className="nav-item" >
                                <Link className={`nav-link py-1 px-2 ${props.pageid == item.id ? 'active' : ''}`} to={item.to}>
                                    {item.name}
                                </Link>
                            </div>
                        )
                    }
                </div>

                {/* <ul className="navbar-nav nav-pills me-auto mb-lg-0">
                    {
                        items && items.length > 0 && items.map((item, index) =>
                            <li key={index} className="nav-item" style={{backgroundColor: 'white'}}>
                                <Link className={`nav-link ${props.pageid == item.id ? 'active' : ''}`} to={item.to}>
                                    {item.name}
                                </Link>
                            </li>
                        )
                    }
                </ul> */}
                
            </div>
        </nav>
    )
}

export default SubNav;