import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  addGradientColor,
  addGradientColorByClass,
  removeAllGradientColor,
  scrollWidthOffset,
} from "./commonFnc";
import { ReactComponent as LogoImg } from "../assets/images/logo.svg";
import { translations } from "../services/localization";

const Footer = () => {
  const { hash } = useLocation();
  const content = translations.footer;
  useEffect(() => {
    let navClass;
    switch (hash) {
      case "#features":
        navClass = "navFeature";
        break;
      case "#nfts":
        navClass = "navNFTs";
        break;
      case "#guide":
        navClass = "navGuide";
        break;
      case "#contact":
        navClass = "navContact";
        break;
      default:
        navClass = "navHome";
        break;
    }
    if (navClass) {
      addGradientColorByClass(navClass);
      scrollWidthOffset(document.getElementsByClassName(navClass)[0]);
    }

    return () => {
      removeAllGradientColor();
    };
  }, [hash]);

  return (
    <div className="row w-100 footer mx-0 py-5">
      <div className="col-12 content">
        <div className="container-fluid px-lg-5 py-2">
          {/* for pc */}
          <div className="float-center links d-none d-lg-flex">
            <FooterLink
              className="nav-link"
              text={content.home}
              targetClass={"navHome"}
              aria-current="page"
              to="/"
            />
            <FooterLink
              className="nav-link"
              text={content.features}
              targetClass={"navFeature"}
              scrollwidthoffset={scrollWidthOffset}
              to="/#features"
              hashlink={1}
            />
            <FooterLink
              className="nav-link"
              text={content.nfts}
              targetClass={"navNFTs"}
              scrollwidthoffset={scrollWidthOffset}
              to="/search"
              hashlink={1}
            />
            <FooterLink
              className="nav-link"
              text={content.guide}
              targetClass={"navGuide"}
              scrollwidthoffset={scrollWidthOffset}
              to="/#guide"
              hashlink={1}
            />
            <FooterLink
              className="nav-link"
              text={content.news}
              targetClass={"navNews"}
              scrollwidthoffset={scrollWidthOffset}
              to="/information"
              hashlink={1}
            />
            <FooterLink
              className="nav-link"
              text={content.contact}
              targetClass={"navNews"}
              scrollwidthoffset={scrollWidthOffset}
              to="/#contact"
              hashlink={1}
            />

            <FooterLink
              className="nav-link"
              text={content.privacy}
              targetClass={"navPrivacy"}
              aria-current="page"
              to="/privacy"
            />
            <FooterLink
              className="nav-link"
              text={content.terms}
              targetClass={"navTerms"}
              aria-current="page"
              to="/terms"
            />
            {/* show in desktop */}
            <FooterLink
              className="nav-link d-block d-md-none"
              text={content.commerceLaw}
              targetClass={"navLaw"}
              aria-current="page"
              to="/commerce-law"
            />
            {/* <FooterLink
              className="nav-link"
              text={content.policy}
              targetClass={"navLaw"}
              aria-current="page"
              to="/commerce-law"
            />
            <FooterLink
              className="d-none nav-link"
              text={content.tearm_of_use}
              targetClass={"navLaw"}
              aria-current="page"
              to="/commerce-law"
            /> */}
          </div>
          {/* for mobile */}
          <div className="float-center links d-lg-none">
            <FooterLink
              className="nav-link"
              text={content.home}
              targetClass={"navHome"}
              aria-current="page"
              to="/"
            />
            <FooterLink
              className="nav-link"
              text={content.features}
              targetClass={"navFeature"}
              scrollwidthoffset={scrollWidthOffset}
              to="/#features"
              hashlink={1}
            />
            <FooterLink
              className="nav-link"
              text={content.nfts}
              targetClass={"navNFTs"}
              scrollwidthoffset={scrollWidthOffset}
              to="/search"
              hashlink={1}
            />
            <FooterLink
              className="nav-link"
              text={content.guide}
              targetClass={"navGuide"}
              scrollwidthoffset={scrollWidthOffset}
              to="/#guide"
              hashlink={1}
            />
            <FooterLink
              className="nav-link"
              text={content.news}
              targetClass={"navNews"}
              scrollwidthoffset={scrollWidthOffset}
              to="/information"
              hashlink={1}
            />
            <FooterLink
              className="nav-link"
              text={content.contact}
              targetClass={"navNews"}
              scrollwidthoffset={scrollWidthOffset}
              to="/#contact"
              hashlink={1}
            />

            {/* <FooterLink
              className="nav-link"
              text={content.privacy}
              targetClass={"navPrivacy"}
              aria-current="page"
              to="/privacy"
            />
            <FooterLink
              className="nav-link"
              text={content.terms}
              targetClass={"navTerms"}
              aria-current="page"
              to="/terms"
            /> */}
          </div>
          <div>
            <LogoImg className="footer-logo" />
            <div className="copyright fw-bold d-none d-sm-block">
              &copy;{new Date().getFullYear()} OCTILLION, All rights reserved
            </div>
          </div>

          {/* for mobile */}
          <div className="float-center links d-flex d-lg-none">
            {/* <FooterLink
              className="nav-link"
              text={content.policy}
              targetClass={"navLaw"}
              aria-current="page"
              to="/commerce-law"
            />
            <FooterLink
              className="nav-link"
              text={content.tearm_of_use}
              targetClass={"navLaw"}
              aria-current="page"
              to="/commerce-law"
            /> */}
            <FooterLink
              className="nav-link"
              text={content.privacy}
              targetClass={"navPrivacy"}
              small
              aria-current="page"
              to="/privacy"
            />
            <FooterLink
              className="nav-link"
              text={content.terms}
              small
              targetClass={"navTerms"}
              aria-current="page"
              to="/terms"
            />
            <FooterLink
              className="nav-link"
              text={content.commerceLaw}
              small
              targetClass={"navLaw"}
              aria-current="page"
              to="/commerce-law"
            />
          </div>
          <div>
            <LogoImg className="footer-logo d-none" />
            <div className="copyright fw-bold d-block d-sm-none copy_small">
              &copy;{new Date().getFullYear()} OCTILLION, All rights reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

export const FooterLink = ({
  hashlink,
  scrollwidthoffset,
  text,
  targetClass,
  to,
  small,
}) => {
  const Text = () => (
    <span
      className={`title gradient-text-hover ${
        small ? "nav_small" : ""
      } ${targetClass}`}
      onClick={() => addGradientColorByClass(targetClass)}
    >
      {text}
    </span>
  );

  return (
    <>
      {hashlink ? (
        <HashLink
          className="nav-link"
          scroll={scrollwidthoffset}
          to={to || "#"}
        >
          <Text />
        </HashLink>
      ) : (
        <Link className="nav-link" aria-current="page" to={to || "#"}>
          <Text />
        </Link>
      )}
    </>
  );
};
