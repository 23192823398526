import React from "react";
import { MaintenancePCICON, MaintenanceSPICON } from "../components/Icon";
import { useMediaQuery } from 'react-responsive'

const MaintenancePC = () => {
  return (
    <div className="d-flex flex-column align-items-center maintenance-mode">
      <h1 className="text title">ただいまメンテナンス中です</h1>
      <div className="img">
        <MaintenancePCICON />
      </div>
      <p className="text description">
        現在、緊急メンテナンスを実施しております。
        <br />
        メンテナンス中はサービスを利用することができませんので、ご了承ください。
        <br />
      </p>
      <p className="text footer">@ 2023 Octillion</p>
    </div>
  );
};

const MaintenanceSP = () => {
  return (
    <div className="d-flex flex-column align-items-center maintenance-mode">
      <h1 className="text title">ただいま<span className="d-block">メンテナンス中です</span></h1>
      <div className="img">
        <MaintenanceSPICON />
      </div>
      <p className="text description">
        現在、バージョンアップに伴うメンテナンスを実施して
        <br />
        おります。メンテナンス中はサービスを利用することが
        <br />
        できませんので、ご了承ください。
        <br />
        <br />
        ※メンテナンス時間は前後することがございます。
      </p>
      <p className="text footer">@ 2023 Octillion</p>
    </div>
  );
};

const Maintenance = () => {
  const isSP = useMediaQuery({ maxWidth: 576 })

  return (
    <>
      {isSP
        ? <MaintenanceSP />
        : <MaintenancePC />
      }
    </>
  );
}

export default Maintenance;
