import React, { useState, useEffect } from "react";
import { Route, Navigate, useLocation, Outlet } from "react-router-dom";
import AppWrapper from "./components/AppWrapper";
import { getToken } from "./services/storage";
import ScrollToTop from "./components/ScrollToTop";
import Routes, { CustomRouter } from "./auth/routes";
import { Web3authProvider } from "./context/web3authContext";
import { AuthProvider } from "./context/authContext";
import { history } from "./auth/history";
import { AppProvider } from "./context/appContext";
import ModalView from "./Modal/SortModalview";
import { getMaintenance } from "./services/maintenance";
import Loading from "./components/Loading";

function App() {
  const [maintenance, setIsMaintenanceMode] = useState(null);

  useEffect(() => {
    (async () => {
      const isMaintenanceMode = await getMaintenance();
      setIsMaintenanceMode(isMaintenanceMode);
    })();
  }, []);

  return (
    <CustomRouter history={history}>
      <div className="App">
        <AppProvider>
          <Web3authProvider>
            <AuthProvider>
              {/* <ModalView /> */}
              <AppWrapper maintenance={maintenance}>
                <ScrollToTop>
                  {maintenance === null ? (
                    <Loading className="h-100" />
                  ) : (
                    <Routes maintenance={maintenance} />
                  )}
                </ScrollToTop>
              </AppWrapper>
            </AuthProvider>
          </Web3authProvider>
        </AppProvider>
      </div>
    </CustomRouter>
  );
}

export default App;
