import { ADAPTER_EVENTS } from "@web3auth/base";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "../components/Swal";
import RPC, { initWeb3, web3authModalParams, openloginAdapter } from "../components/web3RPC";
import { REACT_APP_PORTAL_URL, REACT_APP_R_SERVER } from "../config";
import { fetchData, getData } from "../services/fetch";
import { useDidUpdateEffect } from "../services/hook";
import { getLocalIdToken, getLocalIsDoneIDMS, getLocalIsDoneMetamask, getTempToken, getWalletAddress, getUserId, setIsDoneIDMS, setIsDoneMetamask, setLocalIdToken, setLocalIsDoneIDMS, setLocalIsDoneMetamask, setTempToken, setToken, setValue, setWalletAddress } from "../services/storage";

export const Web3authContext = createContext(); // could have a default value

export const Web3authProvider = ({ children }) => {
    const navigate = useNavigate();
    const [wallet_address, setWallet_address] = useState('');
    const [isDoneIDMS, setIsDoneIDMS] = useState(false);
    const [isDoneMetamask, setIsDoneMetamask] = useState(false);
    const [id_token, setId_token] = useState('');
    const [web3auth, setWeb3auth] = useState(null);
    const [provider, setProvider] = useState(null);
    const [web3Initialzed, setWeb3Initialized] = useState(false);
    const [approveData, setApproveData] = useState(null);
    const [isRedirected, setIsRedirected] = useState(false);

    const setWalletProvider = useCallback(
        async (web3authProvider) => {
            if (web3authProvider) {
                setProvider(web3authProvider);
            };
        }, []
    );

    useEffect(() => {
        const subscribeAuthEvents = (web3auth) => {
            // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS
            web3auth.on(ADAPTER_EVENTS.CONNECTED, (data) => {
                setWalletProvider(web3auth.provider);
            });

            web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
                console.log("connecting");
            });

            web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
                console.log("disconnected");
            });

            web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
                console.error("some error or user has cancelled login request", error);
            });
        };
        const init = async () => {
            try {
                const web3auth = initWeb3();
                // const openloginAdapter = await OpenloginAdapter(openAdapterSettings);
                setWeb3auth(web3auth);
                subscribeAuthEvents(web3auth);
                // web3auth.configureAdapter(openloginAdapter);
                await web3auth.init();
                if (web3auth.connected) {
                    setProvider(web3auth.provider)
                }
            } catch (e) {
                console.log('init error', e)
            }
        };

        setWallet_address(checkWalletAddress(getWalletAddress()));
        setId_token(getLocalIdToken());
        setIsDoneIDMS(getLocalIsDoneIDMS() == 'true');
        setIsDoneMetamask(getLocalIsDoneMetamask() == 'true');
        init();

        return () => {
            setWeb3Initialized(false);
            setApproveData(null);
        };
    }, [])

    useDidUpdateEffect(() => {
        setWalletAddress(checkWalletAddress(wallet_address));
    }, [wallet_address])

    useDidUpdateEffect(() => {
        setLocalIdToken(id_token || '');
    }, [id_token])

    useDidUpdateEffect(() => {
        setLocalIsDoneIDMS(isDoneIDMS);
    }, [isDoneIDMS])

    useDidUpdateEffect(() => {
        setLocalIsDoneMetamask(isDoneMetamask);
    }, [isDoneMetamask])

    const checkWalletAddress = (wallet_address) => {
        return (wallet_address && wallet_address != null && wallet_address != undefined && wallet_address != "null" && wallet_address != "undefined") ? wallet_address : '';
    }

    const getAccounts = async () => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const address = await rpc.getAccounts();
        return address;
    };

    const isValidWalletAddress = async () => {
        const address = await getAccounts();
        return String(address) === String(wallet_address);
    }

    const getMessage = async () => {
        var csrf_response = await getData(`sanctum/csrf-cookie`, true);
        if (csrf_response.ok) {
            var response = await getData(`api/metamask-signature`, true);
            if (response.ok) {
                const { message } = response.data;
                return message;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    const getSignature = async (message) => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        if (!message) {
            console.log("Generating signature needs signing message");
            return;
        }
        const rpc = new RPC(provider);
        const signature = await rpc.signMessage(message);
        if (signature.message) {
            web3Logout();
            Swal.fire({
                icon: 'error',
                title: 'エラーが発生しました',
                text: signature.message
            });
            return null;
        } else {
            return signature;
        }
    };

    const connect = async () => {
        try {
            const message = await getMessage();
            console.log(message);
            const signature = await getSignature(message);
            console.log({ signature })
            const address = await getAccounts();
            console.log({ address })
            if (signature) {
                var response = await fetchData(`api/metamask-connect`, {
                    wallet_address: address,
                    user_id: getUserId(),
                    signature,
                    message
                }, 'post', true, getTempToken());
                // var response = { ok: true, data: { access_token: 'test', isDoneIDMS: true, isDoneMetamask: false, wallet_address: 'test', user: {} } }
                if (response.ok) {
                    if (response.data) {
                        const { access_token, isDoneIDMS, isDoneMetamask, wallet_address, user } = response.data;
                        console.log('metamask-connect', { isDoneIDMS, isDoneMetamask });
                        setIsDoneIDMS(isDoneIDMS);
                        setIsDoneMetamask(isDoneMetamask);
                        if (isDoneIDMS && isDoneMetamask) {
                            setToken(access_token);
                            setTempToken('');
                            setValue('user_info', user);
                            setWallet_address(wallet_address);
                            // navigate('/');
                        } else {
                            navigate('/register');
                        }
                    }
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'エラーが発生しました',
                    text: 'No signature'
                })
            }
        } catch (error) {
            web3Logout();
        }
    }

    const web3Logout = async () => {
        if (!web3auth) {
            console.log("web3auth not initialized yet");
            return;
        }
        if (provider) {
            await web3auth.logout();
        }
        setProvider(null);
        setWallet_address('');
        setWeb3Initialized(false);
        setApproveData(null);
        setIsRedirected(false);
    };

    const web3SoftLogout = async () => {
        if (!web3auth) {
            console.log("web3auth not initialized yet");
            return;
        }
        await web3auth.logout();
        setWeb3Initialized(false);
        setApproveData(null);
        setProvider(null);
        setIsRedirected(false);
    };

    useEffect(() => {
        if (provider && approveData) {
            approveContract(approveData);
        } else if (provider && web3Initialzed) {
            if (isRedirected) {

            } else {
                connect();
            }
        }
    }, [provider])

    const loginLevica = () => {
        const current_url = window.location.href; // window.location.protocol+'//'+window.location.host

        const url = REACT_APP_PORTAL_URL + 'login/idms/9999999/Octillion?r=' + current_url + '&response_type=token&r_server=' + REACT_APP_R_SERVER

        window.location.href = url
    }

    const login = async (id_token = null) => {
        console.log('login id_token')
        const web3auth = initWeb3();
        if (web3auth.connected) {
            console.log('connected')
            setWalletProvider(web3auth.provider)
            return;
        }
        setWeb3auth(web3auth);

        const adapter = openloginAdapter();
        web3auth.configureAdapter(adapter);

        await web3auth.init();

        if (web3auth.connected) {
            setWeb3auth(web3auth)
            setProvider(web3auth.provider);
            return;
        };

        if (!web3auth) {
            console.log("web3auth not initialized yet");
            return;
        }

        if (!id_token) {
            loginLevica();
            return false;
        }

        console.log(adapter.name)
        console.log("TOKEN:" + id_token)
        const loginParams = {
            mfaLevel: 'none',
            loginProvider: "jwt",
            extraLoginOptions: {
                //verifierIdField: "sub",
                id_token: id_token,
                domain: window.location.protocol + "//" + window.location.host,
                verifierIdField: "sub",
                response_type: "token",
                scope: "email profile openid",
            }
        }
        console.log(loginParams)
        const web3authProvider = await web3auth.connectTo(adapter.name, loginParams);
        setWalletProvider(web3authProvider);
        console.log('connected')
    };

    const web3Login = async (id_token) => {
        if (provider) {
            if (isRedirected) {

            } else {
                connect();
            }
        } else {
            login(id_token);
            setWeb3Initialized(true);
        }
    }

    const web3Approve = async (token_type_id, token_id, func, isCancelled = false) => {
        console.log('web3Approve', provider);
        // debugger;
        if (provider) {
            approveContract({ token_type_id, token_id, func });
        } else {
            if (id_token) {
                login(id_token, web3Approve);
                setApproveData({ token_type_id, token_id, func });
            } else {
                // let web3auth_id_token = getLocalIdToken();
                // console.log('web3Approve', { web3auth_id_token }, !!web3auth_id_token)
                // if (web3auth_id_token) {
                //     login(web3auth_id_token);
                // } else {
                //     loginLevica();
                // }
                loginLevica();
            }
        }
    }

    const approveContract = async ({ token_type_id, token_id, func }) => {
        setApproveData(null);
        setIsRedirected(false);
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const isValid = await isValidWalletAddress();
        console.log('approveContract', { isValid, provider, token_type_id, token_id, func })
        if (isValid) {
            const rpc = new RPC(provider);
            var hash = await rpc.approveContract(token_type_id, token_id);
            if (hash && func) {
                func(hash);
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'エラーが発生しました',
                text: 'Different wallet is connected. If you change wallet, please login again.'
            }).then((result) => {
                if (result.isConfirmed) {
                    web3SoftLogout();
                }
            });
        }
    };

    const value = useMemo(() => ({
        wallet_address, setWallet_address,
        isDoneIDMS, setIsDoneIDMS,
        isDoneMetamask, setIsDoneMetamask,
        id_token, setId_token,
        web3auth, setWeb3auth,
        provider, setProvider,
        isRedirected, setIsRedirected,
        getAccounts,
        // transferContract,
        isValidWalletAddress,
        checkWalletAddress,
        web3Login, web3Logout, web3Approve, web3SoftLogout, loginLevica, login
    }), [wallet_address, id_token, isDoneIDMS, isDoneMetamask, web3auth, provider, isRedirected, web3auth]);

    return (
        <Web3authContext.Provider value={value}>
            {children}
        </Web3authContext.Provider>
    )
}
