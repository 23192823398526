import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import { Collapse } from "bootstrap";
import data from "../data/faqs";
import { translations } from "../services/localization";

const FAQs = () => {
  const { categories, faqs } = data;
  const [accordion_key, setAccordion_key] = useState(100000);
  const [selected_category_id, setSelected_category_id] = useState(1);
  const content = translations.home.faq;
  const handleReinitialize = (id) => {
    setSelected_category_id(id);
    setAccordion_key((accordion_key) => accordion_key + 1);
  };

  return (
    <div id="faqs">
      <div
        className="container mt-5"
        id="detail"
        style={{ marginBottom: 218 }}
      >
          <div className="p-faq">
            <h2 className="text-center title mb-3">{content.title}</h2>
            <ul
              className="nav nav-pills nav-fill-transparent nav-fill my-5 px-0"
              id="myTab"
              role="tablist"
            >
              {categories.map((category, index) => (
                <li
                  key={`faq_category_key_${index}`}
                  className="nav-item"
                  role="presentation"
                  onClick={() => handleReinitialize(category.id)}
                  style={{ minWidth: "fit-content" }}
                >
                  <button
                    className={`nav-link ${
                      selected_category_id === category.id ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    data-bs-target={`#faq${category.id}tab`}
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    {category.name}
                  </button>
                </li>
              ))}
            </ul>
            <div className="tab-content">
              {categories.map((category, index) => (
                <div
                  key={`faq_category_content_key_${index}`}
                  className={`tab-pane ${
                    selected_category_id === category.id ? "active" : ""
                  }`}
                  id={`#faq${category.id}tab`}
                  role="tabpanel"
                  aria-labelledby=""
                  tabIndex={index}
                >
                  <div className="row">
                    <div className="accordion" id={`faqs${category.id}`}>
                      {faqs &&
                        faqs
                          .filter((x) => x.category_id == category.id)
                          .map((faq, index) => (
                            <FAQ
                              accordion_key={accordion_key}
                              faq={faq}
                              key={`faq_accor_${
                                category.id
                              }_${Math.random()}`}
                              index={index}
                            />
                          ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
      </div>
    </div>
  );
};

export default FAQs;

const FAQ = ({ faq, index, accordion_key }) => {
  return (
    <div
      className="accordion-item faq-accordion-item mb-3"
      key={`faq${index}_${accordion_key}`}
    >
      <h2 className="accordion-header" id={`faq${index}`}>
        <button
          className="accordion-button collapsed"
          //   style={{ fontSize: 20 }}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapseFaq${index}`}
          aria-expanded="true"
          aria-controls={`collapseFaq${index}`}
        >
          {faq.question}
        </button>
      </h2>
      <div
        id={`collapseFaq${index}`}
        className={`accordion-collapse collapse`}
        aria-labelledby={`faq${index}`}
      >
        <div
          className="accordion-body collapsed"
          //   style={{ fontSize: 30 }}
          data-bs-toggle="collapse"
          data-bs-target={`#collapseFaq${index}`}
        >
          {faq.answer}
        </div>
      </div>
    </div>
  );
};
