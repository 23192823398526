import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Item, { BookmarkItem } from "../../components/Item";
import Loading from "../../components/Loading";
import MyPageWrapper from "../../components/MyPageWrapper";
import SubNav from "../../components/SubNav";
import { getData } from "../../services/fetch";
import { translations } from "../../services/localization";

const Bookmark = () => {
  const navigate = useNavigate();
  const [bookmarks, setBookmarks] = useState([]);
  const [loading, setLoading] = useState(false);

  const getBookmarks = async () => {
    setLoading(true);
    var response = await getData(`api/tokens/bookmarks/my-bookmarks`);
    if (response.ok) {
      setBookmarks(response.data.bookmarks.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getBookmarks();

    return () => setBookmarks([]);
  }, []);

  return (
    <MyPageWrapper pageid="ブックマーク">
      <SubNav
        pageid="ブックマーク"
        items={[
          {
            id: "ブックマーク",
            name: translations.myPageWrapper.list.bookmark,
            to: "/mypage/bookmarks",
          },
          {
            id: "閲覧履歴",
            name: translations.myPageWrapper.list.browsingHistory,
            to: "/mypage/history",
          },
        ]}
      />
      <div className="">
        <div className="row w-100 mx-0">
          <div className="col-lg-10 col-12 px-0">
            <div className="d-flex flex-row align-items-end mb-4 d-none d-sm-block">
              <h3 className="text-start">
                {translations.myPageWrapper.list.bookmark}
              </h3>
            </div>
          </div>
        </div>
        <div className="row w-100 mx-0">
          {loading ? (
            <div className="col-12 px-0">
              <Loading />
            </div>
          ) : (
            // <div className='col-lg-10 col-12 px-0'>
            <div className="col-12 px-0">
              <div className="row item-list">
                {bookmarks &&
                  bookmarks.length > 0 &&
                  bookmarks.map((bookmark, index) => (
                    <div
                      key={index}
                      className="col-res item-col col-res-xxxxl-20 col-res-xxl-3 col-md-4 col-sm-6 col-6 px-1 px-sm-2"
                    >
                      <Item
                        key={index}
                        item={bookmark.token}
                        onClickBtn={() =>
                          navigate(
                            `/detail/${bookmark.token_type_id}/${bookmark.token_id}`
                          )
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </MyPageWrapper>
  );
};

export default Bookmark;
