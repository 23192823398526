import React from "react";
import "../assets/scss/loadingNew.scss";
import { ReactComponent as LoadingSVG } from "../assets/icons/loading.svg";

const Loading = ({ className, overlay = false }) => {
    return (
        <div className={`l-wrapper flex-column ${overlay ? "overlay-loading" : ""} ${className || ''}`}>
            {/* <LoadingSVG /> */}
            <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h3 className='mt-3'>LOADING</h3>
        </div>
    )
}

export default Loading;
