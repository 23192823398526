export const dummyTokens = {
    "contract_address": "0x61f682353E404a3F1C1987493Fd8d475C66c827B",
    "search_word": "",
    "sort_by": "DESC",
    "total": 52,
    "page": 1,
    "tokens": [
        {
            "token_id": 53,
            "image": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmPMxKbR5sHyamUEarQVANaj19xihQWukS8qngtVmVi9Go.jpg",
            "ipfs_image": "https://ipfs.octillion.jp/ipfs/QmPMxKbR5sHyamUEarQVANaj19xihQWukS8qngtVmVi9Go",
            "thumbnail": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmPMxKbR5sHyamUEarQVANaj19xihQWukS8qngtVmVi9Go.jpg",
            "name": "arika",
            "owner": "0x5436549b3581de9fec733C8E1a02de32F5036723",
            "creator": "arika",
            "metadata": {
                "name": "arika",
                "creator": "arika",
                "image": "QmPMxKbR5sHyamUEarQVANaj19xihQWukS8qngtVmVi9Go",
                "description": "aaa"
            },
            "greenStar": 1,
            "elements": 3,
            "bloodline": "---",
            "birthday": "2022-12-14",
            "FOR": 886,
            "ABS": 646,
            "DFT": 359,
            "MND": 646,
            "INT": 119,
            "EXP": 885,
            "bid_price": 0,
            "is_bookmarked": false,
            "token_type_id": 1,
            "ask_price": 0
        },
        {
            "token_id": 52,
            "image": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmfXTYZrxdrRXkdSaaYTsZmR79XCLA4nYi1CRDFVzErKcv.jpg",
            "ipfs_image": "https://ipfs.octillion.jp/ipfs/QmfXTYZrxdrRXkdSaaYTsZmR79XCLA4nYi1CRDFVzErKcv",
            "thumbnail": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmfXTYZrxdrRXkdSaaYTsZmR79XCLA4nYi1CRDFVzErKcv.jpg",
            "name": "ARCANA-18",
            "owner": "0xaee6D5Bb971052854d74F7EBEbF3042727aDCE92",
            "creator": "Guest",
            "metadata": {
                "name": "ARCANA-18",
                "creator": "Guest",
                "image": "QmfXTYZrxdrRXkdSaaYTsZmR79XCLA4nYi1CRDFVzErKcv",
                "description": ""
            },
            "greenStar": 9,
            "elements": 5,
            "bloodline": "---",
            "birthday": "2022-12-14",
            "FOR": 421,
            "ABS": 157,
            "DFT": 3554,
            "MND": 1485,
            "INT": 1951,
            "EXP": 1662,
            "bid_price": 0,
            "is_bookmarked": false,
            "token_type_id": 1,
            "ask_price": 0
        },
        {
            "token_id": 51,
            "image": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmW2ye1V839hbFhqiw4oPFr2xp3WCGExnyhMWhmG96KwVB.jpg",
            "ipfs_image": "https://ipfs.octillion.jp/ipfs/QmW2ye1V839hbFhqiw4oPFr2xp3WCGExnyhMWhmG96KwVB",
            "thumbnail": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmW2ye1V839hbFhqiw4oPFr2xp3WCGExnyhMWhmG96KwVB.jpg",
            "name": "ARCANA-17",
            "owner": "0xccFA88EaF93AEFbAf05e10005397DA4d50E811e4",
            "creator": "Guest",
            "metadata": {
                "name": "ARCANA-17",
                "creator": "Guest",
                "image": "QmW2ye1V839hbFhqiw4oPFr2xp3WCGExnyhMWhmG96KwVB",
                "description": ""
            },
            "greenStar": 4,
            "elements": 5,
            "bloodline": "---",
            "birthday": "2022-12-14",
            "FOR": 118,
            "ABS": 1270,
            "DFT": 1254,
            "MND": 1270,
            "INT": 246,
            "EXP": 230,
            "bid_price": 0,
            "is_bookmarked": false,
            "token_type_id": 1,
            "ask_price": 0
        },
        {
            "token_id": 50,
            "image": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmcS7mjhJTLpFao5WsGkWaW8g9YkB5bUQmmWoBUZQ3fTob.jpg",
            "ipfs_image": "https://ipfs.octillion.jp/ipfs/QmcS7mjhJTLpFao5WsGkWaW8g9YkB5bUQmmWoBUZQ3fTob",
            "thumbnail": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmcS7mjhJTLpFao5WsGkWaW8g9YkB5bUQmmWoBUZQ3fTob.jpg",
            "name": "ARCANA-16",
            "owner": "0x97cF6574119380801b323f8C8fBC0476ffC01F77",
            "creator": "Guest",
            "metadata": {
                "name": "ARCANA-16",
                "creator": "Guest",
                "image": "QmcS7mjhJTLpFao5WsGkWaW8g9YkB5bUQmmWoBUZQ3fTob",
                "description": ""
            },
            "greenStar": 3,
            "elements": 3,
            "bloodline": "---",
            "birthday": "2022-12-14",
            "FOR": 593,
            "ABS": 487,
            "DFT": 416,
            "MND": 595,
            "INT": 833,
            "EXP": 333,
            "bid_price": 0,
            "is_bookmarked": false,
            "token_type_id": 1,
            "ask_price": 0
        },
        {
            "token_id": 49,
            "image": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/Qmcsgh4CYiQmm1jjREwVRgBxfDWN9zDAo5NQM4qBdwdkgz.jpg",
            "ipfs_image": "https://ipfs.octillion.jp/ipfs/Qmcsgh4CYiQmm1jjREwVRgBxfDWN9zDAo5NQM4qBdwdkgz",
            "thumbnail": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/Qmcsgh4CYiQmm1jjREwVRgBxfDWN9zDAo5NQM4qBdwdkgz.jpg",
            "name": "ARCANA-12",
            "owner": "0xccFA88EaF93AEFbAf05e10005397DA4d50E811e4",
            "creator": "Guest",
            "metadata": {
                "name": "ARCANA-12",
                "creator": "Guest",
                "image": "Qmcsgh4CYiQmm1jjREwVRgBxfDWN9zDAo5NQM4qBdwdkgz",
                "description": "new anigame"
            },
            "greenStar": 7,
            "elements": 1,
            "bloodline": "---",
            "birthday": "2022-12-14",
            "FOR": 1480,
            "ABS": 352,
            "DFT": 1693,
            "MND": 1681,
            "INT": 1841,
            "EXP": 1275,
            "bid_price": 0,
            "is_bookmarked": false,
            "token_type_id": 1,
            "ask_price": 0
        },
        {
            "token_id": 48,
            "image": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmST9NUrpdb6S3di68F1cgZkVuHiVYszUoSe8dPUuQ7s7u.jpg",
            "ipfs_image": "https://ipfs.octillion.jp/ipfs/QmST9NUrpdb6S3di68F1cgZkVuHiVYszUoSe8dPUuQ7s7u",
            "thumbnail": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmST9NUrpdb6S3di68F1cgZkVuHiVYszUoSe8dPUuQ7s7u.jpg",
            "name": "ARCANA-11",
            "owner": "0x97cF6574119380801b323f8C8fBC0476ffC01F77",
            "creator": "Guest",
            "metadata": {
                "name": "ARCANA-11",
                "creator": "Guest",
                "image": "QmST9NUrpdb6S3di68F1cgZkVuHiVYszUoSe8dPUuQ7s7u",
                "description": ""
            },
            "greenStar": 5,
            "elements": 0,
            "bloodline": "---",
            "birthday": "2022-12-14",
            "FOR": 121,
            "ABS": 1396,
            "DFT": 1449,
            "MND": 169,
            "INT": 1140,
            "EXP": 184,
            "bid_price": 0,
            "is_bookmarked": false,
            "token_type_id": 1,
            "ask_price": 0
        },
        {
            "token_id": 47,
            "image": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/Qmc3dbVgZypwBeun86zv1CeiQYcggRU3KmjKnihVAMtnbN.jpg",
            "ipfs_image": "https://ipfs.octillion.jp/ipfs/Qmc3dbVgZypwBeun86zv1CeiQYcggRU3KmjKnihVAMtnbN",
            "thumbnail": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/Qmc3dbVgZypwBeun86zv1CeiQYcggRU3KmjKnihVAMtnbN.jpg",
            "name": "ARCANA-5",
            "owner": "0x97cF6574119380801b323f8C8fBC0476ffC01F77",
            "creator": "Guest",
            "metadata": {
                "name": "ARCANA-5",
                "creator": "Guest",
                "image": "Qmc3dbVgZypwBeun86zv1CeiQYcggRU3KmjKnihVAMtnbN",
                "description": ""
            },
            "greenStar": 5,
            "elements": 2,
            "bloodline": "---",
            "birthday": "2022-12-14",
            "FOR": 165,
            "ABS": 1449,
            "DFT": 100,
            "MND": 1141,
            "INT": 1205,
            "EXP": 121,
            "bid_price": 0,
            "is_bookmarked": false,
            "token_type_id": 1,
            "ask_price": 0
        },
        {
            "token_id": 44,
            "image": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmZC1Aw1yFdbb1b3PhVdZCFzt53L4WLZqiFdvkmxsLEmpF.jpg",
            "ipfs_image": "https://ipfs.octillion.jp/ipfs/QmZC1Aw1yFdbb1b3PhVdZCFzt53L4WLZqiFdvkmxsLEmpF",
            "thumbnail": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmZC1Aw1yFdbb1b3PhVdZCFzt53L4WLZqiFdvkmxsLEmpF.jpg",
            "name": "ARCANA-140",
            "owner": "0x8e452784448da2e9688d61da90958ed06731c1E4",
            "creator": "Guest",
            "metadata": {
                "name": "ARCANA-140",
                "creator": "Guest",
                "image": "QmZC1Aw1yFdbb1b3PhVdZCFzt53L4WLZqiFdvkmxsLEmpF",
                "description": ""
            },
            "greenStar": 7,
            "elements": 4,
            "bloodline": "---",
            "birthday": "2022-12-14",
            "FOR": 854,
            "ABS": 1255,
            "DFT": 1290,
            "MND": 215,
            "INT": 1447,
            "EXP": 975,
            "bid_price": 0,
            "is_bookmarked": false,
            "token_type_id": 1,
            "ask_price": 0
        },
        {
            "token_id": 46,
            "image": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmSTGDMbJmdsJBEaPHGwcp4REnumFZB7euqWuk2KCEjRJY.jpg",
            "ipfs_image": "https://ipfs.octillion.jp/ipfs/QmSTGDMbJmdsJBEaPHGwcp4REnumFZB7euqWuk2KCEjRJY",
            "thumbnail": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmSTGDMbJmdsJBEaPHGwcp4REnumFZB7euqWuk2KCEjRJY.jpg",
            "name": "ARCANA-141",
            "owner": "0x3C13259C9027767B408A9486f04b880c77D55ce3",
            "creator": "Guest",
            "metadata": {
                "name": "ARCANA-141",
                "creator": "Guest",
                "image": "QmSTGDMbJmdsJBEaPHGwcp4REnumFZB7euqWuk2KCEjRJY",
                "description": ""
            },
            "greenStar": 2,
            "elements": 5,
            "bloodline": "---",
            "birthday": "2022-12-14",
            "FOR": 358,
            "ABS": 360,
            "DFT": 238,
            "MND": 870,
            "INT": 360,
            "EXP": 492,
            "bid_price": 0,
            "is_bookmarked": false,
            "token_type_id": 1,
            "ask_price": 0
        },
        {
            "token_id": 45,
            "image": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmfCGXVTotAcs44MpF6EUDhc4YBPqUyXijCYniVRRg6nUp.jpg",
            "ipfs_image": "https://ipfs.octillion.jp/ipfs/QmfCGXVTotAcs44MpF6EUDhc4YBPqUyXijCYniVRRg6nUp",
            "thumbnail": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmfCGXVTotAcs44MpF6EUDhc4YBPqUyXijCYniVRRg6nUp.jpg",
            "name": "ARCANA-139",
            "owner": "0x2031Af77c8eD13452A9713Af73a643C642d3A8C7",
            "creator": "Guest",
            "metadata": {
                "name": "ARCANA-139",
                "creator": "Guest",
                "image": "QmfCGXVTotAcs44MpF6EUDhc4YBPqUyXijCYniVRRg6nUp",
                "description": ""
            },
            "greenStar": 4,
            "elements": 2,
            "bloodline": "---",
            "birthday": "2022-12-14",
            "FOR": 1252,
            "ABS": 230,
            "DFT": 1124,
            "MND": 1126,
            "INT": 100,
            "EXP": 1252,
            "bid_price": 0,
            "is_bookmarked": false,
            "token_type_id": 1,
            "ask_price": 0
        },
        {
            "token_id": 43,
            "image": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmTSWWS35d9ULaEXMx2ccpXxLNUrH4FVDXTrwWBVSpic53.jpg",
            "ipfs_image": "https://ipfs.octillion.jp/ipfs/QmTSWWS35d9ULaEXMx2ccpXxLNUrH4FVDXTrwWBVSpic53",
            "thumbnail": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmTSWWS35d9ULaEXMx2ccpXxLNUrH4FVDXTrwWBVSpic53.jpg",
            "name": "ARCANA-102",
            "owner": "0xccFA88EaF93AEFbAf05e10005397DA4d50E811e4",
            "creator": "Guest",
            "metadata": {
                "name": "ARCANA-102",
                "creator": "Guest",
                "image": "QmTSWWS35d9ULaEXMx2ccpXxLNUrH4FVDXTrwWBVSpic53",
                "description": ""
            },
            "greenStar": 1,
            "elements": 4,
            "bloodline": "---",
            "birthday": "2022-12-13",
            "FOR": 132,
            "ABS": 357,
            "DFT": 870,
            "MND": 612,
            "INT": 372,
            "EXP": 646,
            "bid_price": 0,
            "is_bookmarked": false,
            "token_type_id": 1,
            "ask_price": 0
        },
        {
            "token_id": 42,
            "image": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmTpxGjpbGV3mYMVi3w1SQEHif9qXnaFQ8pkJsP7BWyXXk.jpg",
            "ipfs_image": "https://ipfs.octillion.jp/ipfs/QmTpxGjpbGV3mYMVi3w1SQEHif9qXnaFQ8pkJsP7BWyXXk",
            "thumbnail": "https://anicana-api-cache-prod.s3.amazonaws.com/thumbnail/QmTpxGjpbGV3mYMVi3w1SQEHif9qXnaFQ8pkJsP7BWyXXk.jpg",
            "name": "ARCANA-103",
            "owner": "0xccFA88EaF93AEFbAf05e10005397DA4d50E811e4",
            "creator": "Guest",
            "metadata": {
                "name": "ARCANA-103",
                "creator": "Guest",
                "image": "QmTpxGjpbGV3mYMVi3w1SQEHif9qXnaFQ8pkJsP7BWyXXk",
                "description": "sample"
            },
            "greenStar": 1,
            "elements": 4,
            "bloodline": "---",
            "birthday": "2022-12-13",
            "FOR": 916,
            "ABS": 869,
            "DFT": 101,
            "MND": 660,
            "INT": 103,
            "EXP": 916,
            "bid_price": 0,
            "is_bookmarked": false,
            "token_type_id": 1,
            "ask_price": 0
        }
    ],
    "has_next_page": true,
    "limit": "12"
}