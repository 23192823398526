import React, { lazy, Suspense, useLayoutEffect, useState } from "react";
import {
  Routes as ReactRoutes,
  Route,
  useLocation,
  Navigate,
  Outlet,
  Router,
} from "react-router-dom";

import Home from "../views/Home";
import { Arcanagen } from "../views/web3/arcanagen";
import { getToken } from "../services/storage";
import { history } from "./history";
import Page404 from "../views/Page404";
import AppWrapper from "../components/AppWrapper";
import MyArcana from "../views/MyPage/MyArcana";
import MyPersona from "../views/MyPage/MyPersona";
import ReceiveOffer from "../views/MyPage/ReceiveOffer";
import SendOffer from "../views/MyPage/SendOffer";
import SellTransaction from "../views/MyPage/SellTransaction";
import BuyTransaction from "../views/MyPage/BuyTransaction";
import Payment from "../views/MyPage/Payment";
import TradeDetail from "../views/MyPage/TradeDetail";
import Account from "../views/MyPage/Account";
import Bookmark from "../views/MyPage/Bookmark";
import History from "../views/MyPage/History";
import FunctionSetting from "../views/MyPage/FunctionSetting";
import Maintenance from "../components/Maintenance";

const Routes = ({ maintenance }) => {
  // I have import this commented routes directly for not flashing screen for the first time load.
  //   const MyArcana = lazy(() => import("../views/MyPage/MyArcana"));
  //   const MyPersona = lazy(() => import("../views/MyPage/MyPersona"));
  //   const ReceiveOffer = lazy(() => import("../views/MyPage/ReceiveOffer"));
  //   const SendOffer = lazy(() => import("../views/MyPage/SendOffer"));
  //   const SellTransaction = lazy(() => import("../views/MyPage/SellTransaction"));
  //   const BuyTransaction = lazy(() => import("../views/MyPage/BuyTransaction"));
  //   const Payment = lazy(() => import("../views/MyPage/Payment"));
  //   const TradeDetail = lazy(() => import("../views/MyPage/TradeDetail"));
  // const Bookmark = lazy(() => import('../views/MyPage/Bookmark'));
  //   const Account = lazy(() => import("../views/MyPage/Account"));
  //   const History = lazy(() => import("../views/MyPage/History"));
  //   const FunctionSetting = lazy(() => import("../views/MyPage/FunctionSetting"));
  // const Home = lazy(() => import("../views/Home"));
  const SearchResult = lazy(() => import("../views/SearchResult"));
  const Detail = lazy(() => import("../views/Detail"));
  const BiddingComplete = lazy(() => import("../views/BiddingComplete"));
  const SellingComplete = lazy(() => import("../views/SellingComplete"));
  const SellingPriceConfirmation = lazy(() =>
    import("../views/SellingPriceConfirmation")
  );
  const LoginWithIDMS = lazy(() => import("../views/Auth/LoginWithIDMS"));
  const ForgetIDMS = lazy(() => import("../views/Auth/ForgetIDMS"));
  const ForgetEmail = lazy(() => import("../views/Auth/ForgetEmail"));
  const Login = lazy(() => import("../views/Auth/Login"));
  const Register = lazy(() => import("../views/Auth/Register"));
  const RegisterWithIDMS = lazy(() => import("../views/Auth/RegisterWithIDMS"));
  const BiddingPriceConfirmation = lazy(() =>
    import("../views/BiddingPriceConfirmation")
  );
  const Information = lazy(() => import("../views/Information"));
  const Guide = lazy(() => import("../views/guide"));
  const Arcana = lazy(() => import("../views/Arcana"));
  const Persona = lazy(() => import("../views/Persona"));
  const Password = lazy(() => import("../views/MyPage/Password"));
  const Terms = lazy(() => import("../views/Terms"));
  const Privacy = lazy(() => import("../views/Privacy"));
  const CommerceLaw = lazy(() => import("../views/CommerceLaw"));
  const AccountDelete = lazy(() => import("../views/MyPage/AccountDelete"));
  const PaymentEdit = lazy(() => import("../views/MyPage/PaymentEdit"));
  const InformationDetail = lazy(() => import("../views/InformationDetail"));
  const Contact = lazy(() => import("../views/Contact"));
  const ForgetIDMSVerify = lazy(() => import("../views/Auth/ForgetIDMSVerify"));
  const UserInfo = lazy(() => import("../views/MyPage/UserInfo"));
  const Logout = lazy(() => import("../views/Auth/Logout"));
  const Notification = lazy(() => import("../views/Notification"));
  const TermPolicy = lazy(() => import("../views/Auth/TermPolicy"));

  if (maintenance) {
    return <Maintenance />;
  }

  return (
    <Suspense fallback={<></>}>
      <ReactRoutes>
        <Route path="/" element={<Home />} />
        <Route path="/detail/:token_type_id/:id" element={<Detail />} />
        <Route path="/arcana" element={<Arcana />} />
        <Route path="/persona" element={<Persona />} />
        <Route path="/information" element={<Information />} />
        <Route path="/information/:id" element={<InformationDetail />} />
        <Route path="/notification" element={<Notification />} />
        {/* <Route path="/notification/:id" element={<NotificationDetail />} /> */}
        <Route path="/guide/:id" element={<Guide />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/commerce-law" element={<CommerceLaw />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/term-policy" element={<TermPolicy />} />
        <Route path="/register/idms" element={<RegisterWithIDMS />} />
        <Route path="/login/idms" element={<LoginWithIDMS />} />
        <Route path="/forget/idms" element={<ForgetIDMS />} />
        <Route path="/forget/email" element={<ForgetEmail />} />
        <Route path="/forget/idms/verify" element={<ForgetIDMSVerify />} />
        <Route path="/search" element={<SearchResult />} />
        <Route path="/search/:type" element={<SearchResult />} />
        <Route path="/arcana-gen" element={<Arcanagen />} />
        {/* <Route path="*" element={<Page404 />} /> */}
        <Route element={<RequireAuth />}>
          <Route
            path="/selling-price-confirmation"
            element={<SellingPriceConfirmation />}
          />
          <Route path="/selling-complete" element={<SellingComplete />} />
          <Route
            path="/bidding-price-confirmation"
            element={<BiddingPriceConfirmation />}
          />
          <Route path="/bidding-complete" element={<BiddingComplete />} />
          <Route path="/mypage/owned-arcana" element={<MyArcana />} />
          <Route path="/mypage/owned-persona" element={<MyPersona />} />
          <Route path="/mypage/receive-offer" element={<ReceiveOffer />} />
          <Route path="/mypage/send-offer" element={<SendOffer />} />
          <Route
            path="/mypage/sell-transactions"
            element={<SellTransaction />}
          />
          <Route path="/mypage/buy-transactions" element={<BuyTransaction />} />
          <Route path="/mypage/trade/detail/:id" element={<TradeDetail />} />
          <Route path="/mypage/payment" element={<Payment />} />
          <Route path="/mypage/payment/edit" element={<PaymentEdit />} />
          <Route path="/mypage/account" element={<Account />} />
          <Route path="/mypage/account/password" element={<Password />} />
          <Route path="/mypage/account/info/:type" element={<UserInfo />} />
          {/* <Route path="/mypage/account/kyc" element={<KYC />} /> */}
          <Route path="/mypage/account/delete" element={<AccountDelete />} />
          <Route path="/mypage/bookmarks" element={<Bookmark />} />
          <Route path="/mypage/history" element={<History />} />
          <Route path="/mypage/setting" element={<FunctionSetting />} />
        </Route>
        <Route path="/logout" element={<Logout />} />
      </ReactRoutes>
    </Suspense>
  );
};

export default Routes;

export const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

function RequireAuth() {
  let location = useLocation();
  const token = getToken();
  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
}
